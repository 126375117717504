import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Section } from "../../layout/Section";
import { BodyText } from "../../texts/BodyText";
import { Subtitle } from "../../texts/Subtitle";
import { Hero } from "../../texts/Hero";
import { body } from "../../../constants/fonts";
import { OpenPositionsCard } from "../../cards/OpenPositionsCard";
import { StaticImage } from "gatsby-plugin-image";

export function Summary() {
  return (
    <Box bg="black" color="white">
      <Section centerContent>
        <Flex gap="2rem" flexDirection="column">
          <Flex flexDirection="column" alignItems="center">
            <Hero mb="1rem">Open positions</Hero>
            <BodyText textAlign="center" fontFamily={body}>
              Indevit's team is small but growing. We are a group of
              easy-going IT experts, who have set out to create a workplace
              where we enjoy our work and enjoy our time off. We are looking for
              experienced team members that, just like us, want to take control
              of their work lives – either as employees or independent
              contractors.
            </BodyText>
          </Flex>
          <Flex
            justifyContent="center"
            gap="5rem"
            flexDirection={{ base: "column", lg: "row" }}
          >
            <OpenPositionsCard
              readMoreLink="/dataAnalyst"
              title="Dataanalytiker, Power BI"
              text="Är du expert på Power BI? Vi behöver dig som kan stärka vår databearbetningsstrategi! "
              img={
                <StaticImage
                  alt=""
                  src="../../../images/marica_working1.jpg"
                  placeholder="blurred"
                  layout="fullWidth"
                  transformOptions={{ fit: "contain", cropFocus: "attention" }}
                  style={{
                    width: "100%",
                    height: "50%",
                    maxHeight: "inherit",
                  }}
                />
              }
            />
          </Flex>
        </Flex>
      </Section>
    </Box>
  );
}
